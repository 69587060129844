<template>
  <div class="register bg-slate">
    <div class="lg:flex">
      <div
        class="
          quote
          hidden
          lg:h-screen
          sticky
          top-0
          bottom-0
          left-0
          lg:flex
          justify-center
          items-center
        "
      >
        <div class="px-12">
          <p class="text-xxl text-white leading-10">"</p>
          <p class="text-white text-md leading-9 opacity-80 px-3">
            Those people who develop the ability to continuously acquire new and
            better forms of knowledge that they can apply to their work and to
            their lives will be the movers and shakers in our society for the
            indefinite future
          </p>
          <p class="text-white pt-6 px-3 font-semibold">Brian Tracy</p>
          <div class="svg pt-8 flex justify-end">
            <div class="w-10">
              <img
                class="w-full"
                src="@/assets/Images/register/quote.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          form
          p-5
          m-auto
          md:min-h-screen
          py-10
          md:flex
          justify-center
          items-center
        "
      >
        <div class="w-full">
          <h1 class="text-lg text-center font-bold mb-8">Register</h1>
          <form @submit.prevent="onSubmit" class="">
            <div class="md:flex md:gap-4">
              <div class="md:w-1/2">
                <p class="mb-2"><label for="name">Name</label></p>
                <input
                  class="
                    mb-5
                    w-full
                    rounded-md
                    p-4
                    border border-tetiary
                    transition
                  "
                  v-model="name"
                  type="text"
                  placeholder="Enter your full name"
                />
                <!-- <p class="mb-2"><label for="email">Email</label></p>
                <input
                  class="
                    mb-5
                    w-full
                    rounded-md
                    p-4
                    border border-primary
                    transition
                  "
                  v-model="email"
                  type="email"
                  placeholder="Enter your email"
                /> -->
                <p class="mb-2"><label for="dob">Date of Birth</label></p>
                <input
                  class="
                    mb-5
                    w-full
                    rounded-md
                    p-4
                    border border-tetiary
                    transition
                  "
                  v-model="dob"
                  type="date"
                  placeholder="dd/mm/yyyy"
                />

                <p class="mb-2">
                  <label for="organisation">Organisation</label>
                </p>
                <input
                  class="
                    mb-5
                    w-full
                    rounded-md
                    p-4
                    border border-tetiary
                    transition
                  "
                  v-model="organisation"
                  type="text"
                  placeholder="Enter organisation or school"
                />

                <p class="mb-2"><label for="city">City</label></p>
                <input
                  class="
                    mb-5
                    w-full
                    rounded-md
                    p-4
                    border border-tetiary
                    transition
                  "
                  type="text"
                  placeholder="Enter city"
                  v-model="city"
                />

                <input
                  class="w-full btn mt-8 md:block hidden"
                  type="submit"
                  value="Start Test"
                />
              </div>
              <div class="md:w-1/2">
                <p class="mb-2"><label for="email">Email</label></p>
                <input
                  class="
                    mb-5
                    w-full
                    rounded-md
                    p-4
                    border border-tetiary
                    transition
                  "
                  v-model="email"
                  type="email"
                  placeholder="Enter your email"
                />

                <p class="mb-2"><label for="profession">Profession</label></p>
                <input
                  class="
                    mb-5
                    w-full
                    rounded-md
                    p-4
                    border border-tetiary
                    transition
                  "
                  v-model="profession"
                  type="text"
                  placeholder="eg. Student, Engineer, etc."
                />

                <p class="mb-2"><label for="country">Country</label></p>
                <input
                  class="
                    mb-5
                    w-full
                    rounded-md
                    p-4
                    border border-tetiary
                    transition
                  "
                  v-model="country"
                  type="text"
                  placeholder="Enter your country"
                />
                <p class="mb-2">
                  <label for="phone"
                    >Contact number (Prefix country code)</label
                  >
                </p>
                <input
                  class="
                    mb-5
                    w-full
                    rounded-md
                    p-4
                    border border-tetiary
                    transition
                  "
                  v-model="phone"
                  type="text"
                  placeholder="Enter number"
                />
              </div>
            </div>
            <input
              class="w-full btn mt-8 md:hidden"
              type="submit"
              value="Start Test"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";

export default {
  name: "Register",
  data() {
    return {
      name: "",
      email: "",
      dob: "",
      organisation: "",
      position: "",
      country: "",
      phone: "",
      city: "",
    };
  },
  methods: {
    onSubmit() {
      // clear tokens if exists
      this.$store.dispatch("clearTokens");
      this.$store
        .dispatch("createQuizTaker", {
          name: this.name,
          email: this.email,
          dob: this.dob,
          organization: this.organisation,
          position: this.position,
          country: this.country,
          phone: this.phone,
          city: this.city,
        })
        .then(() => {
          this.$router.push({ name: "Home" });
        });
    },
    // md5 unhashing
    unhashText(hash) {
      var str = hash;
      var hex = str.toString();
      for (var n = 0; n < hex.length; n += 2)
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
      return str;
    },
  },
  mounted() {
    // if route has params
    if (this.$route.query.share_code) {
      var encrypted = this.$route.query.share_code; //python is base64 ECB
      var key = "AAAAAAAAAAAAAAAA"; //key used in Python
      key = CryptoJS.enc.Utf8.parse(key);
      var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        mode: CryptoJS.mode.ECB,
      });
      this.email = decrypted.toString(CryptoJS.enc.Utf8);
    }
  },
};
</script>

<style scoped>
input:focus {
  outline: 1px solid #132b4d;
}

.quote {
  background: url("../assets/Images/register/quote-bg.png");
  width: min(500px, 33.33%);
}

@media screen and (min-width: 976px) {
  .form {
    width: min(1100px, 66.67%);
  }
}
</style>